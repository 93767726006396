import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';

import App from 'App';
import { AuthProvider } from 'context/AuthContext';
import { ToastProvider } from 'context/ToastContext';

import history from 'helpers/history';

ReactDOM.render(
  <Router history={history}>
    <AuthProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
);

// Hot Module Replacement
if (module.hot) module.hot.accept();
