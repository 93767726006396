import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const ToastsContainer = ({ children }) => (
  <div role="alert" aria-atomic="true" className="alerts--container">
    {children}
  </div>
);

ToastsContainer.propTypes = {
  children: PropTypes.node,
};

export default ToastsContainer;
