import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Banner, Paragraph } from '@softiron/design-system';
import {
  IconAlertM as IconAlert,
  IconInfoM as IconInfo,
  IconTickL as IconTick,
  IconCloseM as IconClose,
  themeRed,
  themeYellow,
  themeGreen,
  themeBlue,
} from '@softiron/icons-library';
import classnames from 'classnames';

import ToastsContainer from 'components/ToastsContainer';

const TOAST_TYPES = {
  SUCCESS: {
    theme: 'green',
    iconTheme: themeGreen,
    icon: IconTick,
  },
  INFO: {
    theme: 'blue',
    iconTheme: themeBlue,
    icon: IconInfo,
  },
  WARNING: {
    theme: 'yellow',
    iconTheme: themeYellow,
    icon: IconAlert,
  },
  ERROR: {
    theme: 'red',
    iconTheme: themeRed,
    icon: IconClose,
  },
};

// Context
const ToastContext = createContext();

// Provider
const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const add = useCallback((message, type, delay = 5000) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), delay);
  }, []);

  const show = useMemo(
    () => ({
      success: (message, delay = 5000) =>
        add(message, TOAST_TYPES.SUCCESS, delay),
      info: (message, delay = 5000) => add(message, TOAST_TYPES.INFO, delay),
      error: (message, delay = 5000) => add(message, TOAST_TYPES.ERROR, delay),
      warning: (message, delay = 5000) =>
        add(message, TOAST_TYPES.WARNING, delay),
    }),
    [add]
  );

  const contextValue = useMemo(() => ({ show, toast }), [toast, show]);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};

const useToastContext = () => {
  const context = useContext(ToastContext);
  if (context === undefined)
    throw new Error('useToastContext must be used within a ToastProvider');
  return context;
};

// Tasts container
const ToastBar = () => {
  const { toast } = useContext(ToastContext);

  if (!toast) return null;

  const { message, type: { icon: Icon, theme = '' } = {} } = toast || {};
  return (
    <ToastsContainer>
      <Banner
        theme={theme}
        className={classnames(
          'mb-M banner--shadow color-white',
          `bg-color-${theme}`
        )}
      >
        <div className="flex flex--middle">
          {Icon && <Icon theme={{ primary: '#FFF', secondary: '#FFF' }} />}
          <Paragraph className="mh-M mb-none">{message}</Paragraph>
        </div>
      </Banner>
    </ToastsContainer>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};

export { useToastContext, ToastProvider, ToastBar };
