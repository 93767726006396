const STATUS_SLUG = 2;
const CHART_STEP_SM = 12;
const CHART_STEP_LG = 60;
const CHART_OFFSET_MINUTES = 30;
const INPUT_TEXT_MAX_LENGTH = 100;
const INPUT_IP_MAX_LEGNTH = 15;
const INPUT_MTU_MIN_VAL = 1500;
const INPUT_MTU_MAX_VAL = 10000;
const POOL_PG_MAX = 1000000;
const POOL_PG_MIN = 0;
const POOL_REPLICATION_MAX = 10;
const MIN_STORAGE_DISKS = 2;
const MIN_VALID_STORAGE_NODES = 3;
const MIN_VALID_MONITOR_NODES = 3;
const ERROR_CODE_AUTH = 401;
const ERROR_CODE_FORBIDDEN = 403;
const FULL_PERCENTAGE = 100;
const MILLISECONDS = 1000;
const MIN_REQUIRED_ROUTERS = 2;
const MAX_INITIATORS = 2;
const MAX_ITEMS_PER_PAGE = 50;

export {
  STATUS_SLUG,
  CHART_STEP_SM,
  CHART_STEP_LG,
  CHART_OFFSET_MINUTES,
  INPUT_TEXT_MAX_LENGTH,
  INPUT_IP_MAX_LEGNTH,
  INPUT_MTU_MIN_VAL,
  INPUT_MTU_MAX_VAL,
  POOL_PG_MAX,
  POOL_PG_MIN,
  POOL_REPLICATION_MAX,
  MIN_STORAGE_DISKS,
  MIN_VALID_STORAGE_NODES,
  MIN_VALID_MONITOR_NODES,
  ERROR_CODE_AUTH,
  ERROR_CODE_FORBIDDEN,
  FULL_PERCENTAGE,
  MILLISECONDS,
  MIN_REQUIRED_ROUTERS,
  MAX_ITEMS_PER_PAGE,
  MAX_INITIATORS,
};
