export const NODE_STATUS = {
  CRASHED: 'CRASHED',
  DOWN: 'DOWN',
  MISSING: 'MISSING',
  OFF: 'OFF',
  ON: 'ON',
  PENDING_ON: 'PENDING_ON',
  PENDING_OFF: 'PENDING_OFF',
  PENDING_REBOOT: 'PENDING_REBOOT',
  PENDING_CHANGE: 'PENDING_CHANGE',
  DISCOVERING: 'DISCOVERING',
};

export default {
  [NODE_STATUS.CRASHED]: {
    INDICATOR: 'red',
    STATUS: 'Error',
    TEXT: 'Possible crash.',
  },
  [NODE_STATUS.DOWN]: {
    INDICATOR: 'yellow',
    STATUS: 'Down',
    TEXT: 'Down.',
  },
  [NODE_STATUS.MISSING]: {
    INDICATOR: 'red',
    STATUS: 'Missing',
    TEXT: 'Missing.',
  },
  [NODE_STATUS.OFF]: {
    INDICATOR: 'grey',
    STATUS: 'Off',
    TEXT: 'Powered off.',
  },
  [NODE_STATUS.ON]: {
    INDICATOR: 'green',
    STATUS: 'On',
    TEXT: 'Powered on.',
  },
  [NODE_STATUS.PENDING_ON]: {
    INDICATOR: 'yellow',
    STATUS: 'Powering on',
    TEXT: 'Powering on.',
  },
  [NODE_STATUS.PENDING_OFF]: {
    INDICATOR: 'yellow',
    STATUS: 'Powering off',
    TEXT: 'Powering off.',
  },
  [NODE_STATUS.PENDING_REBOOT]: {
    INDICATOR: 'yellow',
    STATUS: 'Rebooting',
    TEXT: 'Rebooting.',
  },
  [NODE_STATUS.PENDING_CHANGE]: {
    INDICATOR: 'green',
    STATUS: 'Updating',
    TEXT: 'Powered on. Requested changes pending.',
  },
  [NODE_STATUS.DISCOVERING]: {
    INDICATOR: 'yellow',
    STATUS: 'Discovering',
    TEXT: 'Discovering.',
  },
};
