import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import useQuery from 'hooks/useQuery';

const InfoContext = React.createContext({ loaded: false });

const InfoProvider = ({ children }) => {
  const { data: infoList = {}, refetch, loaded } = useQuery('/info/');

  return (
    <InfoContext.Provider value={{ infoList, loaded, updateInfo: refetch }}>
      {children}
    </InfoContext.Provider>
  );
};

const useInfoContext = () => {
  const context = useContext(InfoContext);
  if (context === undefined)
    throw new Error('useInfoContext must be used within a InfoProvider');
  return context;
};

InfoProvider.propTypes = {
  children: PropTypes.node,
};

export { InfoProvider, useInfoContext };
