import axios from 'axios';

import history from 'helpers/history';
import { ERROR_CODE_AUTH } from 'helpers/constants/numbers';

console.log(process.env);

const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST
    ? `${process.env.REACT_APP_API_HOST}/api`
    : `${window.location.origin}/api`,
});

client.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === ERROR_CODE_AUTH) {
      delete client.defaults.headers.common.Authorization;
      localStorage.removeItem('SI-auth');
      history.push('/logout');
    }

    return window.Promise.reject(error);
  }
);

export default client;
