import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';

import useQuery from 'hooks/useQuery';

import api from 'helpers/axios';

import { updateNodeInNodesData, updateNodesDataInPlace } from 'helpers/nodes';

const NodesContext = createContext();

const NodesProvider = ({ children }) => {
  const {
    status,
    loaded,
    error,
    refetch,
    data: nodeList = [],
    setData: setNodeList,
  } = useQuery('/node/', {
    onSetData: (prevData, data) => updateNodesDataInPlace(data, prevData),
  });

  const updateLocalNode = (id) =>
    api.get(`/node/${id}/`).then(({ data }) => {
      setNodeList((prevData) => updateNodeInNodesData(data, prevData));
      return data;
    });

  return (
    <NodesContext.Provider
      value={{
        nodeList,
        loaded,
        status,
        error,
        updateNodeList: refetch,
        updateLocalNode,
      }}
    >
      {children}
    </NodesContext.Provider>
  );
};

const useNodesContext = () => {
  const context = useContext(NodesContext);
  if (context === undefined)
    throw new Error('useNodesContext must be used within a NodesProvider');
  return context;
};

NodesProvider.propTypes = {
  children: PropTypes.node,
};

export { NodesProvider, useNodesContext };
