import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import useInterval from 'hooks/useInterval';
import api from 'helpers/axios';
import { FETCH_STATUS } from 'helpers/constants/fetch-status';

const DEFAULT_SET_DATA = (prevData, data) => data;
const DEFAULT_DELAY = 10000;

const useQuery = (
  url,
  {
    params = {},
    onSuccess,
    onError,
    onSetData = DEFAULT_SET_DATA,
    delay = DEFAULT_DELAY,
    idle = false,
  } = {}
) => {
  const [updated, setUpdated] = useState(new Date());
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const [loaded, setLoaded] = useState(false);

  const refetch = useCallback(() => {
    setUpdated(new Date());
  }, []);

  const reload = useCallback(() => {
    setLoaded(false);
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (idle) return;
    let subscribed = true;
    setStatus(FETCH_STATUS.LOADING);

    api
      .get(url, { params })
      .then(({ data }) => {
        if (subscribed) {
          setData((prevData) => onSetData(prevData, data));
          setStatus(FETCH_STATUS.SUCCESS);
          setError(null);
          if (onSuccess && typeof onSuccess === 'function') onSuccess(data);
        }
      })
      .catch((error) => {
        if (subscribed) {
          setStatus(FETCH_STATUS.FAILED);
          setError(error);
          if (onError) onError(error);
        }
      })
      .finally(() => setLoaded(true));

    return () => (subscribed = false);
  }, [updated, idle]);

  // POLLING
  useInterval(() => {
    if (status !== FETCH_STATUS.LOADING) refetch();
  }, delay);

  return {
    status,
    loaded,
    data,
    setData,
    error,
    refetch,
    reload,
  };
};

useQuery.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.array,
  options: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onSetData: PropTypes.func,
  delay: PropTypes.number,
};

export default useQuery;
