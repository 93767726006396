import React, { lazy, Suspense } from 'react';

import { Loader } from '@softiron/design-system';

import { useAuthContext } from 'context/AuthContext';
import { NodesProvider } from 'context/NodesContext';
import { LockProvider } from 'context/LockContext';
import { InfoProvider } from 'context/InfoContext';
import { SetupProvider } from 'context/SetupContext';
import { ToastBar } from 'context/ToastContext';

const AppAuthenticated = lazy(() => import('AppAuthenticated'));
const AppUnauthenticated = lazy(() => import('AppUnauthenticated'));

const App = () => {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated === null) return <Loader isLoading type="screen" />;

  return (
    <Suspense fallback={<Loader isLoading type="screen" />}>
      <InfoProvider>
        <ToastBar />
        {!isAuthenticated ? (
          <AppUnauthenticated />
        ) : (
          <SetupProvider>
            <LockProvider>
              <NodesProvider>
                <AppAuthenticated />
              </NodesProvider>
            </LockProvider>
          </SetupProvider>
        )}
      </InfoProvider>
    </Suspense>
  );
};

export default App;
