import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import useQuery from 'hooks/useQuery';
import { useToastContext } from 'context/ToastContext';
import { useAuthContext } from 'context/AuthContext';

import api from 'helpers/axios';
import { LOCK_STATES } from 'helpers/constants/lock-states';

// Context
const LockContext = createContext({});

// Provider
const LockProvider = ({ children }) => {
  const isMounted = useRef(true);
  const [lock, setLockStatus] = useState(LOCK_STATES.LOCKED);
  const { show: toast } = useToastContext();
  const { userData = {} } = useAuthContext();

  const { permission_write } = userData;

  // Mounted event
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useQuery('/lock/', {
    delay: 2000,
    onSuccess: ({ held, held_by_user }) => {
      if (permission_write)
        if (held) setLockStatus(LOCK_STATES.UNLOCKED);
        else if (held_by_user) setLockStatus(LOCK_STATES.IN_USE);
        else setLockStatus(LOCK_STATES.LOCKED);
      else setLockStatus(LOCK_STATES.READ_ONLY);
    },
    onError: () => setLockStatus(LOCK_STATES.ERROR),
  });

  const actionLock = () => {
    if ([LOCK_STATES.LOCKED, LOCK_STATES.READ_ONLY].includes(lock)) return;

    return api
      .delete('/lock/')
      .then(() => {
        if (isMounted.current) setLockStatus(LOCK_STATES.LOCKED);
      })
      .catch((error) => toast.error(error.toString()));
  };

  const actionUnlock = () => {
    if (lock === LOCK_STATES.UNLOCKED) return;

    return api
      .post('/lock/')
      .then(({ data }) => data)
      .then(({ held, held_by_user }) => {
        if (held) setLockStatus(LOCK_STATES.UNLOCKED);
        else if (held_by_user) setLockStatus(LOCK_STATES.IN_USE);
        else setLockStatus(LOCK_STATES.LOCKED);
      })
      .catch((error) => {
        toast.error(error.toString());
        setLockStatus(LOCK_STATES.LOCKED);
      });
  };

  const extendLock = () => {
    if (lock !== LOCK_STATES.UNLOCKED) return;

    return api
      .put('/lock/')
      .then(({ data }) => data)
      .then(({ held, held_by_user }) => {
        if (held) setLockStatus(LOCK_STATES.UNLOCKED);
        else if (held_by_user) setLockStatus(LOCK_STATES.IN_USE);
        else setLockStatus(LOCK_STATES.LOCKED);
      })
      .catch((error) => {
        toast.error(error.toString());
        setLockStatus(LOCK_STATES.LOCKED);
      });
  };

  const extendOrUnlock = async () => {
    const extended = await extendLock();
    if (!extended) await actionUnlock();
  };

  const toggleLock = () => {
    const prevLock = lock;
    setLockStatus(LOCK_STATES.LOADING);

    switch (prevLock) {
      case LOCK_STATES.LOCKED:
        actionUnlock();
        break;
      case LOCK_STATES.UNLOCKED:
        actionLock();
        break;
      case LOCK_STATES.IN_USE:
        setLockStatus(LOCK_STATES.IN_USE);
        break;
      default:
        actionLock();
    }
  };

  return (
    <LockContext.Provider
      value={{
        lock,
        toggleLock,
        actionUnlock,
        actionLock,
        extendOrUnlock,
        extendLock,
      }}
    >
      {children}
    </LockContext.Provider>
  );
};

const useLockContext = () => {
  const context = useContext(LockContext);
  if (context === undefined)
    throw new Error('useLockContext must be used within a LockProvider');
  return context;
};

LockProvider.propTypes = {
  children: PropTypes.node,
};

export { useLockContext, LockProvider };
