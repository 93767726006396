import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'helpers/axios';

import useQuery from 'hooks/useQuery';

import { ERROR_CODE_AUTH } from 'helpers/constants/numbers';

const tokenName = 'SI-auth';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { pathname, state = {} } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState();

  // We update user data on load
  useQuery('/token/', {
    delay: null,
    idle: !isAuthenticated,
    onSetData: (prev, data) => setUserData(data),
  });

  useEffect(() => {
    const authToken = sessionStorage.getItem(tokenName);

    // This could be an interceptor
    if (authToken) {
      setIsAuthenticated(true);
      api.defaults.headers.common.Authorization = `Token ${authToken}`;
    } else {
      logout();
      setIsAuthenticated(false);
    }
  }, [logout]);

  function login({ username = '', password = '' }) {
    setLoading(true);

    api
      .post('/token/', { username, password })
      .then(({ data }) => {
        // This could be better somewhere else
        const { token, permission_write, username } = data;
        setUserData({ permission_write, username });
        setErrorMessage('');
        sessionStorage.setItem(tokenName, token);
        api.defaults.headers.common.Authorization = `Token ${token}`;
        setIsAuthenticated(true);
        history.push(state.from || '/');
        setLoading(false);
      })
      .catch(({ response = {} }) => {
        logout({ state: { from: pathname } });
        const { status } = response;
        const newErrorMessage =
          status == ERROR_CODE_AUTH
            ? 'Bad username or password.'
            : 'Could not connect to service. Please try again later.';
        setErrorMessage(newErrorMessage);
        setLoading(false);
        setUserData(null);
      });
  }

  const logout = useCallback((params = {}) => {
    sessionStorage.removeItem(tokenName);
    setIsAuthenticated(false);
    setUserData(null);

    // redirect user with any state they've been given
    if (pathname !== '/login')
      history.push({
        pathname: '/login',
        ...params,
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        loading,
        isAuthenticated,
        errorMessage,
        userData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('useAuthContext must be used within a AuthProvider');
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthProvider, useAuthContext };
