/**
 * Returns a string that will have plural suffix appended, provided amount of things being referred to is greater than 1.
 * @param {string} noun Things being referred to
 * @param {number} count Number of things being referred to
 * @param {string} options.suffix String to append to pluralized items if need be (e.g. 's' or 'es')
 * @returns {string} Appropriately pluralized string
 */
export const pluralize = (noun = '', count = 0, { suffix = 's' } = {}) => {
  const countInt = parseInt(count, 10);
  // if unable to coerce count as Number, return noun as is
  if (Number.isNaN(countInt)) return noun;
  return `${noun}${countInt !== 1 ? suffix : ''}`;
};

/**
 * Lowercase string
 * @param {string} string String to lowercase
 * @returns {string} Same string as input with all characters lowercased
 */
export const lowercase = (string = '') => string.toLowerCase();

/**
 * Captialize string
 * @param {string} string String to capitalize
 * @returns {string} Same string as input with first letter capitalized
 */
export const capitalize = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Remove slug from end of URL path. Helpful for
 * @param {string} slug Slug to slice from end of path
 * @param {string} path Full URL path
 */
export const getPathWithoutSlug = (slug, path = '') =>
  path.split(`/${slug}`)[0];

/**
 * Get date string in YYYYMMDD format
 * @returns {string} String with YYYYMMDD format
 */
export const getYYYYMMDD = (date = new Date()) =>
  date.toISOString().replace(/-/g, '').slice(0, 8);
