import { ROLES } from 'helpers/constants/node-roles';
import { NODE_STATUS } from 'helpers/enums/status-node';
import { lowercase } from 'helpers/strings';
import {
  MIN_VALID_STORAGE_NODES,
  MIN_VALID_MONITOR_NODES,
} from 'helpers/constants/numbers';
import { CAPABILITIES } from 'helpers/constants/node-capabilities';

// NODE API HELPERS
/**
 */
export const updateNodesDataInPlace = (nodesData = [], prevNodesData = []) => {
  const prevNodeIds = prevNodesData.map((node) => node.id);
  return nodesData.map((node) => {
    if (prevNodeIds.includes(node.id))
      return {
        ...prevNodesData.find((n) => n.id === node.id),
        ...node,
      };

    return node;
  });
};

export const updateNodeInNodesData = (nodeData = {}, nodesData = []) => {
  if (!nodesData.length) return nodesData;
  const nodeIndex = nodesData.findIndex((node) => node.id === nodeData.id);
  return Object.assign([], nodesData, {
    [nodeIndex]: nodeData,
  });
};

export const updateDiskInDisksData = (disksData = [], diskList = []) => {
  let updatedDiskList = diskList;

  // probably a more immutable, functional way of handling this
  // but let's get it working first
  // disksData.forEach(diskData => {
  for (let i = 0; i < disksData.length; i++) {
    if (!disksData[i].id) return;
    const diskIndex = updatedDiskList.find(
      (disk) =>
        disk.id === disksData[i].id && disk.node_id === disksData[i].node_id
    );

    // add disk to list
    if (!diskIndex) updatedDiskList.push(disksData[i]);
  }
  // });

  return updatedDiskList;
};

// NODE DATA HELPERS
/**
 * From a node list, returns filter for nodes in cluster
 * @param {object} node Object containing node data
 * @returns {object} Filter function
 */
export const filterByInCluster = ({ in_cluster }) => in_cluster;

/**
 * From a node list, returns filter for monitor role
 * @param {object} node Object containing node data
 * @returns {object} Filter function
 */
export const filterByMonitor = ({ roles }) =>
  roles && roles.map(lowercase).includes(ROLES.MONITOR);

/**
 * From a node list, returns filter for storage role
 * @param {object} node Object containing node data
 * @returns {object} Filter function
 */
export const filterByStorage = ({ roles }) =>
  roles && roles.map(lowercase).includes(ROLES.STORAGE);

/**
 * From a node list, returns filter for manager role
 * @param {object} node Object containing node data
 * @returns {object} Filter function
 */
export const filterByManager = ({ roles }) =>
  roles && roles.map(lowercase).includes(ROLES.MANAGER);

/**
 * From a node list, returns filter for MDS role
 * @param {object} node Object containing nodes list
 * @returns {object} Filter function
 */
export const filterByMDS = ({ roles }) =>
  roles && roles.map(lowercase).includes(ROLES.MDS);

/**
 * From a node list, returns nodes without a role
 * @param {object} node Object containing nodes list
 * @returns {object} Filter function
 */
export const filterByNoRole = ({ roles }) => roles && roles.length === 0;

/**
 * From a node list, returns discovered nodes
 * @param {object} node Object containing nodes list
 * @returns {object} Filter function
 */
export const filterByDiscovered = ({ status }) =>
  status && status !== NODE_STATUS.DISCOVERING;

/**
 * From a node list, returns powered on nodes
 * @param {object} node Object containing nodes list
 * @returns {object} Filter function
 */
export const filterByPoweredOn = ({ status }) =>
  status && status === NODE_STATUS.ON;

/**
 * From a node list, returns filter for Router capability,
 * but without SMB role.
 * @param {object} node Object containing nodes list
 * @returns {object} Filter function
 */
export const filterByUnusedRouter = ({ capabilities, roles }) =>
  capabilities &&
  roles &&
  capabilities.map(lowercase).includes(CAPABILITIES.SMB) &&
  !roles.map(lowercase).includes(ROLES.SMB);

/**
 * From a node list, returns filter for Router capability,
 * but without SMB role.
 * @param {object} node Object containing nodes list
 * @returns {object} Filter function
 */
export const filterByUnusedISCSIRouter = ({ capabilities, roles }) =>
  capabilities &&
  roles &&
  capabilities.map(lowercase).includes(CAPABILITIES.ISCSI) &&
  !roles.map(lowercase).includes(ROLES.ISCSI);

/**
 * From a collection of nodes, count how many are used for storage
 * @param {array} nodes Array of nodes containing `node` data (incl. node.roles)
 * @returns {number} Number of nodes with the role of storage (defaults to 0)
 */
export const getStorageNodeCount = (nodes = []) =>
  nodes.filter(filterByStorage).length || 0;

/**
 * From a collection of nodes, count how many are used as monitor
 * @param {array} nodes Array of nodes containing `node` data (incl. node.roles)
 * @returns {number} Number of nodes with the role of monitor (defaults to 0)
 */
export const getMonitorNodeCount = (nodes = []) =>
  nodes.filter(filterByMonitor).length || 0;

/**
 * From a collection of nodes, count how many are used as MDS
 * @param {array} nodes Array of nodes containing `node` data (incl. node.roles)
 * @returns {number} Number of nodes with the role of MDS (defaults to 0)
 */
export const getMDSNodeCount = (nodes = []) =>
  nodes.filter(filterByMDS).length || 0;

/**
 * From a collection of nodes, count how many have no roles
 * @param {array} nodes Array of nodes containing `node` data (incl. node.roles)
 * @returns {number} Number of nodes with no roles set (defaults to 0)
 */
export const getNoRoleNodeCount = (nodes = []) =>
  nodes.filter(filterByNoRole).length || 0;

/**
 * From a collection of nodes, count how many are used as shadow
 * @param {array} nodes Array of nodes containing `node` data (incl. node.roles)
 * @returns {number} Number of nodes with the role of shadow (defaults to 0)
 */
export const getShadowNodeCount = (nodes = []) =>
  nodes.filter(
    (node) => node.roles && node.roles.map(lowercase).includes(ROLES.SHADOW)
  ).length || 0;

/**
 * From a collection of node disks, count how many of the type
 * @param {array} disks Array of disks containing `disk` data
 * @returns {number} Number of disks with the type (defaults to 0)
 */
export const getDisksCount = (disks = [], typeCheck = 'ssd') => {
  return (
    disks.filter(({ type }) => type && type.toLowerCase() === typeCheck)
      .length || 0
  );
};

/**
 * From a node network object, returns subset of fields
 * @param {object} network Object containing node's network data
 * @returns {object} Subset with selected fields
 */
export const reduceNet = ({ ip_address, subnet, gateway, mtu }) => ({
  ip_address,
  subnet,
  gateway,
  mtu,
});

/**
 * From a node replication network object, returns subset of fields
 * @param {object} network Object containing node's network data
 * @returns {object} Subset with selected fields
 */
export const reduceReplicationNet = ({ ip_address, subnet, mtu }) => ({
  ip_address,
  subnet,
  mtu,
});

/**
 * From a node management network object, returns subset of fields
 * @param {object} network Object containing node's network data
 * @returns {object} Subset with selected fields
 */
export const reduceManagementNet = ({ ip_address, subnet, gateway }) => ({
  ip_address,
  subnet,
  gateway,
});

/**
 * From a node network object, returns subset of fields
 * @param {object} network Object containing node's network data if ip_address
 * is not 0.0.0.0
 * @returns {object} Subset with selected fields
 */
export const reduceNetConditionally = (
  { ip_address, subnet, gateway, mtu },
  reducer
) =>
  ip_address === '0.0.0.0'
    ? { ip_address }
    : reducer({ ip_address, subnet, gateway, mtu });

/**
 * From a node object, returns subset of fields
 * @param {object} node Object containing node's data
 * @returns {object} Subset with selected fields
 */
export const reduceNode = ({ networks, name, roles, asset, in_cluster }) => ({
  networks: defaultNodeNetworks(networks),
  name,
  roles,
  asset,
  in_cluster,
});

const DEFAULT_NODE_NET = { gateway: '', subnet: '', mtu: '', ip_address: '' };
const DEFAULT_CLUSTER_NET = {
  gateway: '',
  subnet: '',
  mtu: '',
  ranges: [{ start: '', end: '' }],
};

/**
 * From a node networks object, returns same object with no empty fields
 * @param {object} node Object containing node networks data
 * @returns {object} Same networks object with no empty fields
 */
export const defaultNodeNetworks = ({
  public: pn,
  replication,
  management,
}) => ({
  public: pn || { ...DEFAULT_NODE_NET },
  replication: replication || { ...DEFAULT_NODE_NET },
  management: management || { ...DEFAULT_NODE_NET },
});

/**
 * From a cluster networks object, returns same object with no empty fields
 * @param {object} node Object containing cluster networks data
 * @returns {object} Same networks object with no empty fields
 */
export const defaultClusterNetworks = ({
  public: pn,
  replication,
  management,
}) => ({
  public: pn || { ...DEFAULT_CLUSTER_NET },
  replication: replication || { ...DEFAULT_CLUSTER_NET },
  management: management || { ...DEFAULT_CLUSTER_NET },
});

/**
 * From a node object, returns subset of fields
 * @param {object} node Object containing node's data
 * @returns {object} Subset with selected fields
 */
export const getModelTitle = (model) => {
  switch (model) {
    case undefined:
      return 'HyperDrive';
    case 'HM11000':
      return 'HyperDrive Storage Manager';
    case 'HD11000':
      return 'HyperDrive Ceph Monitor';
    case 'HR41000':
      return 'HyperDrive Storage Router';
    default:
      return 'HyperDrive Storage';
  }
};

/**
 * From a disks list, returns filter for osds usage
 * @param {object} node Object containing a disk
 * @returns {object} Filter function
 */
export const filterOsdDisks = ({ usage }) =>
  usage.some((use = '') => use && use.toLowerCase().includes('osd'));

/**
 * From a nodes list, returns reducer with all network ips
 * @param {object} Array of nodes
 * @returns {object} Reducer function
 */
export const nodesIPReducer = (
  acc,
  {
    networks: {
      public: { ip_address: pn } = {},
      replication: { ip_address: rn } = {},
      management: { ip_address: mn } = {},
    } = {},
  }
) => acc.concat(pn, rn, mn);
/**
 * From a nodes list, returns if the number of storage nodes is valid
 * @param {object} nodes Array
 * @returns {object} True or false
 */
export const numberOfStorageNodesIsValid = (nodes) =>
  getStorageNodeCount(nodes) >= MIN_VALID_STORAGE_NODES;

/**
 * From a nodes list, returns if the number of monitor nodes is valid
 * @param {object} nodes Array
 * @returns {object} True or false
 */
export const numberOfMonitorNodesIsValid = (nodes) => {
  const count = getMonitorNodeCount(nodes);
  return count >= MIN_VALID_MONITOR_NODES && Boolean(count % 2);
};

/**
 * From a nodes list, returns if the number of MDS nodes is valid
 * @param {object} nodes Array
 * @returns {object} True or false
 */
export const numberOfMDSNodesIsValid = (nodes) => {
  const count = getMDSNodeCount(nodes);
  return count !== 1;
};

/**
 * From a nodes security IDs list, returns IDs formatted
 * @param {object} ids String
 * @returns {object} Array of objects
 */
export const formatNodeIDs = (ids = '') =>
  Boolean(ids)
    ? ids
        .split('\n')
        .filter(Boolean)
        .map((line) => line.replace('#', ''))
        .map((line) => {
          const [machine, security] = line.split(':');
          return {
            machine,
            security,
          };
        })
    : [];

export const nodeIDregex = new RegExp(
  /^#([0-9a-zA-Z]+):([0-9a-fA-F-]+)$/,
  'gm'
);
