import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import useQuery from 'hooks/useQuery';

import api from 'helpers/axios';

const SetupContext = React.createContext();

const SetupProvider = ({ children }) => {
  const [progress, setProgress] = useState({ current: 0, available: 0 });

  const updateProgress = useCallback(
    (current = 0) =>
      setProgress((prev) => ({
        current,
        available: Math.max(prev.available, current),
      })),
    [setProgress]
  );

  const {
    status,
    refetch,
    data: setup,
    setData: setSetup,
    loaded,
  } = useQuery('/setup/status/', {
    delay: null,
  });

  const updateSetupState = ({ data }) =>
    api.post('/setup/status/', data).then((resp) => {
      setSetup((setup) => ({ ...setup, ...data }));
      return resp;
    });

  return (
    <SetupContext.Provider
      value={{
        setup,
        refetch,
        updateSetupState,
        progress,
        updateProgress,
        loaded,
        status,
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

const useSetupContext = () => {
  const context = useContext(SetupContext);
  if (context === undefined)
    throw new Error('useSetupContext must be used within a SetupProvider');
  return context;
};

SetupProvider.propTypes = {
  children: PropTypes.node,
};

export { SetupProvider, useSetupContext };
